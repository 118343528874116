html, body {
  font-family: 'Roboto Slab', Georgia, serif;
}

/* LINKS */

a, .btn-link {
  color: #000;
}

a:hover, .btn-link:hover {
  text-decoration: none;
}

a:hover {
  color: #fff;
  border-bottom: 2px solid #b71c1c;
  background-color: #484848;
}

/* COLORS */

.color-primary {
  color: #212121;
}

.color-primary-light {
  color: #484848;
}

.color-primary-dark {
  color: #000000;
}

.color-secondary {
  color: #b71c1c;
}

.color-secondary-light {
  color: #f05545;
}

.color-secondary-dark {
  color: #7f0000;
}

/* BUTTONS */

.btn-outline-red {
  color: #b71c1c;
  border-color: #b71c1c;
}

.btn-outline-red:hover {
  color: #fff;
  background-color: #b71c1c;
  border-color: #b71c1c;
}

/* FORMS */

.form-control:focus {
  border-color: #b71c1c;
  box-shadow: 0 0 0 0.2rem rgba(127, 0, 0, .25);
}

/* TABLES */

.table-hover tbody tr:hover {
  color: #fff;
  background-color: #484848;
  /* border-bottom: 2px solid #b71c1c;
  margin-bottom: -2px; */
}

/* UTILS */

.rotate180 {
  transform: rotate(180deg);
}